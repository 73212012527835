import React, {  useState } from "react";
import {
    Modal,
} from "react-bootstrap";
import axios from "axios";
import { NODE_API_URL} from "./../../../env_config"
// import { GetDiamondDB ,StoreDiamondDB} from "../../_helpers/IndexedDB";
// import { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../_helpers";
import { ConfirmStatusPopUpNew } from "./ConfirmStatusPopUpNew";
import { useSelector } from "react-redux";

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};
export const BuyPopUpNew = (props) => {
    const [loader,setLoader] = useState(false);
    const {hideCertificateNumber,hideEstimatedShippingDays } = useSelector(
        (state) => state.CcModeSettingReducer
      );
    // const [update,setUpdate] = React.useState(props.Shortlisted);
    // const [successOpen, setSuccessOpen] = useState(false);
    // const userData = useMemo(()=>localStorage.getItem("userData"),[])
    // const user = JSON.parse(userData)
    // const id = useMemo(()=>localStorage.getItem("C_id"),[])
    // const userCurrency = useMemo(()=>localStorage.getItem("userCurrency"),[])
    // const usercur = JSON.parse(userCurrency)
    const [diamondstatusshow,setDiamondStatusShow] = useState(false)
    const [diamondstatus,setDiamondStatus] = useState([])
    const handleClose = () => props.setShow(false);
    // const handleShow = () => props.setShow(true);
    // const [comment,SetComment] = useState()
        // Get device information
        function detectDevice() {
            // Check if the user agent contains the substring "Mobi"
            return /Mobi/.test(navigator.userAgent) ? "Mobile" : "Pc";
        }
    
        function detectOS() {
            const userAgent = navigator.userAgent;
            if (/Windows/.test(userAgent)) {
                return "Windows";
            } else if (/Macintosh/.test(userAgent)) {
                return "Mac OS";
            } else if (/Linux/.test(userAgent)) {
                return "Linux";
            } else if (/Android/.test(userAgent)) {
                return "Android";
            } else if (/iPhone|iPad|iPod/.test(userAgent)) {
                return "iOS";
            } else {
                return "Unknown";
            }
        }

    function handleShortlist(props) {
        setLoader(true)
        let certificates = props.data.map(value => value.Certi_No).toString()
        axios({
            method: "POST",
            url: `${NODE_API_URL}/ccmode/ccmodeBuyDiamond`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
              },
            data: {
                user_id:props.user.CustomerId,
                Certi_NO:certificates,
                diamond_type:props.data[0].C_Color?props.data[0].diamond_type:props.data[0].diamond_type + "F",
                ConsumerId:localStorage.getItem("C_id")
            },
        })
            .then(function(res) {
                if(res.data.message === "UnAuthorized"){
                    localStorage.clear();
                    window.location.reload(true)
                  }
                handleClose()
                setLoader(false)
                let finalstatus = []
                for(let i = 0; i < props.data.length; i++){
                    for(let j = 0; j < res.data.length;j++){
                        if(res.data[j].CERTIFICATE === props.data[i].Certi_No){
                            let status = "Not Confirmed"
                            if(res.data[j].Message === "Diamond(s) added in Confirm Diamond List."){
                                status = "Confirmed"
                            }
                            finalstatus.push({...props.data[i],status:status})
                        }
                    }
                }
                setDiamondStatusShow(true)
                setDiamondStatus(finalstatus)
                if(finalstatus.filter(val => val.status === "Confirmed").length){
                    axios({
                        method: "POST",
                        url: `${NODE_API_URL}/ccmode/buyUserLog`,
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                          },
                        data: {
                            user_id:props.user.CustomerId,
                            activity_type:"Ccmode Buy",
                            user_agent:navigator.userAgent,
                            activity_data:finalstatus.filter(val => val.status === "Confirmed"),
                            ip_address:localStorage.getItem("ipData"),
                            consumer_id:localStorage.getItem("C_id"),
                            diamond_type:"null"
                        }
                    }).then(res => {
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                    }).catch(error => {
                    })
                }
            })
            .catch(function(res) {
                // console.log(res);
            });
    }
    function numberWithCommas(x) {
        if(x){
            return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
    return (
        <>
            <ConfirmStatusPopUpNew GetPricePerCarat={props.GetPricePerCarat} GetPrice={props.GetPrice} show={diamondstatusshow} data={props.data} onClose={() => setDiamondStatusShow(false)} diamondstatus={diamondstatus} language={props.language}/>
            <Modal
                show={props.show}
                onHide={handleClose}
                className={"natTab"}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="row">
                        <div className="col-12 d-flex justify-content-between mb-3">
                            <span>{props.language.CONFIRM_GOODS_POPUP_CONFIRM_DIAMOND} : {props.diamondcount}</span>
                        </div>
                        <div className="col-12">
                            <PerfectScrollbar
                                options={perfectScrollbarOptions}
                                className="scroll border-bottom"
                                style={{ maxHeight: "322px", position: "relative" }}
                            >
                                <table className="table border-bottom">
                                    <thead>
                                        <tr className="bg-gray-100 sticky-top">
                                            <th className="text-center py-3">{props.language.CONFIRM_GOODS_POPUP_DIAMOND_DETAILS}</th>
                                            <th className="text-center py-3"> {!hideEstimatedShippingDays ?props.language.CONFIRM_GOODS_POPUP_DIAMOND_SHIPPING_DAYS:""}</th>
                                            <th align="center" className="text-right py-3">
                                                {props.language.CONFIRM_GOODS_POPUP_OURPRICEUSD}({props.Currency}) <br />
                                                {props.Currency}/CT
                                            </th>
                                            {/* <th className="text-center  py-3 align-middle">
                                                Client <br />
                                                Reference
                                            </th> */}
                                            {/* <th align="center" className="text-right py-3">
                                                {props.language.CONFIRM_GOODS_POPUP_OURPRICEINR} <br />
                                                INR/CT
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {props.data && props.data.map((value,index) => (
                                        <tr className={`position-relative`} key={index}>
                                            <td>
                                                {/* {value.country !== "INDIA"?
                                                    <span className="CanNotHold"> Unfortunately, Diamond location outside India cant be ordered</span>
                                                :""} */}
                                                {/* {value.can_hold === 1 ? "":  <span style={{fontSize:"8px",color:"red"}}> DIAMOND NOT AVAILabLE FOR HOLD</span> } */}
                                                <div className={`row`}>
                                                    <div className="col-2 text-center p-0">
                                                        {value.AWS_Image ? (
                                                            <img className="img-fluid mr-2" alt={value.C_Shape} src={value.AWS_Image} width="50px" />
                                                        ) : (
                                                            <img className="img-fluid mr-2" alt={value.C_Shape} src={toAbsoluteUrl(`/media/shape/${value.C_Shape.toLowerCase()}-no.png`)} onError={e => {
                                                                e.currentTarget.src = toAbsoluteUrl(
                                                                    `/media/shape/noimage.jpg`
                                                                )
                                                            }} width="50px" style={{ backgroundColor:"#f4f4f4", }} />
                                                        )}
                                                    </div>
                                                    <div className="col-10 pl-2 p-0">
                                                        <div className="mb-1">
                                                            <span
                                                                className={`${
                                                                    value.diamond_type === "N" || value.diamond_type === "W"
                                                                        ? value.C_Color === "fancy" ? "F":"W"  
                                                                        : value.C_Color === "fancy" ? "LF":"L" 
                                                                }_diamond mr-3 small`}
                                                            >
                                                                {value.diamond_type === "N" || value.diamond_type === "W"
                                                                    ? value.C_Color === "fancy" ? "NF":"N"  
                                                                    : value.C_Color === "fancy" ? "LF":"L" }
                                                            </span>
                                                            
                                                            <span className="small mr-2">
                                                                {value.diamond_type === "N" || value.diamond_type === "W" ? "N" : "L"}-{value.ProductId}
                                                            </span>
                                                            <span className="text-primary small">
                                                                {value.Lab}
                                                                {!hideCertificateNumber?`-${value.Certi_No}`:""}
                                                            </span>
                                                        </div>

                                                        <span className="text-dark font-weight-bold d-block">
                                                            {value.C_Shape}{" "}
                                                            {value.C_Weight && value.C_Weight.toFixed(2)}{" "}
                                                            {value.C_Color === "fancy" ? (
                                                                <>
                                                                    {value.f_intensity}{" "}
                                                                    {value.f_overtone}{" "}
                                                                    {value.f_color}{" "}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {value.C_Color}{" "}
                                                                </>
                                                            )}
                                                            {value.C_Clarity}{" "}
                                                            {value.C_Cut}{" "}
                                                            {value.C_Polish}{" "}
                                                            {value.C_Symmetry}{" "}
                                                            {value.C_Fluorescence}{" "}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={`text-center `}>
                                                {!hideEstimatedShippingDays ?`${value.CustomerShippingDays} days`:""} 
                                            </td> 
                                            <td className={`text-right text-primary `}>
                                            {props.symbol} {numberWithCommas(props.GetPrice(value))}
                                                {/* {value.show_supplier === 1?<OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                           Supplier Price: ${value["SupplierRate"]}<br/>
                                                           Our Charges: ${(Math.round(value["newcharge"] * 100)/100).toFixed(2)} 
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="ml-2 text-hover-primary cursor-pointer"><FaInfoCircle /></span>
                                                </OverlayTrigger>:""}<br/> */}
                                                <br/>
                                                <span className="text-muted small">{props.Currency}/CT {numberWithCommas(props.GetPricePerCarat(value))}</span>
                                            </td>
                                            {/* <td className={`text-right text-primary `}>
                                                ₹{numberWithCommas(value.TOTAL_PRICE)} <br />
                                                <span className="text-muted small">₹/CT {numberWithCommas(value.PRICE_PER_CTS)}</span>
                                            </td> */}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </PerfectScrollbar>
                            <div className="text-primary font-weight-bold mt-4 font-italic" >{props.diamondcount > 4 ? "Scroll for explore all diamonds":""}</div>
                            {/* {errorMessage && <p className="mb-1 text-danger">{errorMessage}</p>} */}
                            <div className="row mt-5">
                                <div className="col-12 font-size-h6 text-right">
                                    Per {props.Currency}/CT Price : {props.symbol} {numberWithCommas(props.totalpricepercarat)}
                                </div>
                                <div className="col-12 font-size-h6 mt-2 text-right font-weight-bolder">
                                    Total {props.Currency} : {props.symbol} {numberWithCommas(props.totalourprice)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className={`btn font-weight-bold btn-secondary`}
                        onClick={handleClose}
                    >
                        {props.language.CONFIRM_GOODS_POPUP_CLOSE}
                    </button>
                    <button
                        className={`btn font-weight-bold btn-primary ml-2`}
                        onClick={() =>
                            handleShortlist(props)
                        }
                        disabled={loader}
                    >
                        {loader && <span className="ml-1 spinner spinner-white"></span>}
                        {props.language.CONFIRM_GOODS_POPUP_CONFIRM_ORDER}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
