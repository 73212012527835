/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState,useMemo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Checkbox,CircularProgress,FormControlLabel } from "@material-ui/core";
import { BsCircle,BsCheckCircleFill, BsArrowLeftShort } from 'react-icons/bs';
import { toAbsoluteUrl } from "../../_helpers";
import { BuyPopUpNew } from "../popup/BuyPopUpNew";
import { SwalWarn } from "../popup/SwalWarn";
import {FETCH_CONSUMER_SHORTLIST_DATA, NODE_API_URL} from "./../../../env_config"
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateShortlistStoneCount } from "../../../redux/commonRedux/common.actions";
import { Link, useHistory } from "react-router-dom";
import CertificateLink from "../CertificateLink";
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};

export const CartDetails = ({language,ConsumerId}) => {
    const history =useHistory();
    const [selected, setSelected] = React.useState([]);
    const [selectedstones,setSelectedStone]  = React.useState([]);
    const [show, setShow] = useState(false);
    const [totalcarat,setTotalCarat] = useState(0)
    const [totalourprice,setTotalOurPrice] = useState(0)
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()    
    const [loader,setLoader]=useState(true)
    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)
    const [consumerData, setConsumerData] = useState([]);
    const [availabilitydata,setAvailabilityData] = useState([])

    const dispatch = useDispatch();
    const shortListedStoneCount = useSelector((state) => {
      return state.ShortlistStoneReducer?.shortlistStoneCount;
    });
    const { hidePrice, hideCertificateNumber,displaybuybtn } = useSelector(
      (state) => state.CcModeSettingReducer
    );
    function Currency(Currency){
        if (Currency === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (Currency === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (Currency === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (Currency === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (Currency === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (Currency === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (Currency === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (Currency === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (Currency === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (Currency === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (Currency === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }else if (Currency === "SGD") {
            const  currencyIcon = "$"
            return currencyIcon
        }else if (Currency === "THB") {
            const  currencyIcon = "฿"
            return currencyIcon
        }else if (Currency === "MYR") {
            const  currencyIcon = "MYR"
            return currencyIcon
        }else if (Currency === "BND") {
            const  currencyIcon = "$"
            return currencyIcon
        }else if (Currency === "DKK") {
            const  currencyIcon = "DKK"
            return currencyIcon
        }else if (Currency === "SEK") {
            const  currencyIcon = "SEK"
            return currencyIcon
        }
    }
// console.log(ConsumerId)
    useEffect(() => {
        const finalData = `{  "customerId": ${user.CustomerId}, "ConsumerId":${ConsumerId.ConsumerId} }`;
        const getShortListData = async () =>  {
        
            axios({
            method: 'POST',
            url: FETCH_CONSUMER_SHORTLIST_DATA,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
              },
                data: finalData,
            })
                .then( (res) =>{
                    if(res.data.message === "UnAuthorized"){
                        localStorage.clear();
                        window.location.reload(true)
                      }
                
                setConsumerData( res.data.Result || [])
            })
            .catch(function (res) {
                console.log(res)
            });
        };
            
            getShortListData();
           
        }, [user.CustomerId , ConsumerId]);
    useEffect(() => {
        if(consumerData && consumerData.length){
            let temptotalcarat = 0
        let temptotalourprice = 0
        let filterselect = consumerData.filter(value => {
            if(selected.includes(value.Certi_No)){
                temptotalcarat += parseFloat(value.C_Weight)
                temptotalourprice += parseFloat(GetPrice(value))
                return {
                    ...value
                }
            }
        })
        setSelectedStone(filterselect)
        setTotalCarat(temptotalcarat)
        setTotalOurPrice(temptotalourprice)
        }
    },[selected])
    useEffect(() => {
        if (consumerData && consumerData.length) {
            let availibilitycheck = []
            for (let i = 0; i < consumerData.length; i++) {
                if (consumerData[i]?.status_api_available?.toString() === "1") {
                    availibilitycheck.push({
                        sup_id: consumerData[i]?.sup_id,
                        stoneno: consumerData[i]?.Loat_NO,
                        diamond_type: consumerData[i]?.diamond_type,
                        doc_stock_id: consumerData[i]?.id
                    })
                }
            }
            if (availibilitycheck.length) {
                let BASEURL = "https://api.staging.global.diamondsupload.com"
                if (process.env.REACT_APP_FETCH_COUNSUMER_LIST === "https://api.ciccio2.diazoom.com") {
                    BASEURL = "https://api.global.diamondsoncall.com"
                }
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${BASEURL}/supplier/getAvailibilityStatus`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        supplierdata: availibilitycheck
                    }
                };
                function debounce(func, delay) {
                    let timeoutId;
                    return function (...args) {
                        clearTimeout(timeoutId);
                        timeoutId = setTimeout(() => {
                            func.apply(this, args);
                        }, delay);
                    };
                }
                // Debounced Axios request
                const debouncedRequest = debounce((config) => {
                    axios.request(config)
                        .then((response) => {
                            setLoader(false)
                            if (response?.data?.data) {
                                setAvailabilityData(response?.data?.data);
                            }
                        })
                        .catch((error) => {
                            setLoader(false)
                            console.log(error);
                        });
                }, 300); // 300ms delay
                debouncedRequest(config);
            }
            else{
                setLoader(false)
            }
        }
    }, [consumerData])
    console.log(availabilitydata,"availabilitydata")
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }
        setSelected(newSelected);
  };
  
  const removeDiamond = () => {
    let shortlistedStoneIds = [];
    consumerData.forEach((stone) => {
      if (selected.includes(stone.Certi_No)) {
        return shortlistedStoneIds.push(stone.Id);
      }
    });

    const config = {
      method: "POST",
      url: `${NODE_API_URL}/ccmode/removeShortlistedStone`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      },
      data: {
        CustomerId: user.CustomerId,
        Id: shortlistedStoneIds,
      },
    };

    axios(config).then((res) => {
        if(res.data.message === "UnAuthorized"){
            localStorage.clear();
            window.location.reload(true)
        }
        setTotalOurPrice(0)
        setSelectedStone(0)
        setTotalCarat(0)
        setLoader(false)
        setConsumerData(
            consumerData.filter((elem) => !shortlistedStoneIds.includes(elem.Id))
        );
        dispatch(
            updateShortlistStoneCount({
            shortListedStoneCount:
                shortListedStoneCount - shortlistedStoneIds.length,
            })
        );
    });
  };

  const BuyDiamond = () => {
    if(!selectedstones.length){
        setSwalWarnOpen(true)
        setAlertMessage("Please Select At Least One Stone!")
        return
    }
    let inactive = false
    for(let i = 0;i < selectedstones.length;i++){
        let available = true
        let checkavailable = availabilitydata.find(avvalue => avvalue?.stoneno?.toString() === selectedstones[i].Loat_NO?.toString())
        if (checkavailable?.status === "UnAvailable") {
            available = false
        }
        if(selectedstones[i].Status === "1" || !available){
            inactive = true
        }
    }
    if(inactive){
        setSwalWarnOpen(true)
        setAlertMessage("Please Select Only Active Stones!")
        return
    }
    setShow(true)
  }

  const HandleMultiSelect = (event) => {
    if(event.target.checked){
        let alldata = consumerData.filter(value => {
            if(value.customer_country?.toUpperCase() === "INDIA"){
                if(value.country?.toUpperCase() === "INDIA"){
                    return value
                }
            }
            else{
                return value
            }
        }).map(value => value.Certi_No)
        setSelected(alldata)
    }
    else{
        setSelected([])
    }
  }

  function numberWithCommas(x) {
    if(x){
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else{
        return "0.00"
    }
}


const GetPrice = (data) => {
    let selectedconversionrate = 1 
    if (localStorage.getItem("user_currency") === "INR") {
        selectedconversionrate = data.currencies.cur_inr
    } 
    if (localStorage.getItem("user_currency") === "HKD") {
        selectedconversionrate = data.currencies.cur_hkd
    } 
    if (localStorage.getItem("user_currency") === "AUD") {
        selectedconversionrate = data.currencies.cur_aud
    } 
    if (localStorage.getItem("user_currency") === "EUR") {
        selectedconversionrate = data.currencies.cur_eur
    } 
    if (localStorage.getItem("user_currency") === "CAD") {
        selectedconversionrate = data.currencies.cur_cad
    } 
    if (localStorage.getItem("user_currency") === "CNY") {
        selectedconversionrate = data.currencies.cur_cny
    } 
    if (localStorage.getItem("user_currency") === "GBP") {
        selectedconversionrate = data.currencies.cur_gbp
    } 
    if (localStorage.getItem("user_currency") === "NZD") {
        selectedconversionrate = data.currencies.cur_nzd
    } 
    if (localStorage.getItem("user_currency") === "JPY") {
        selectedconversionrate = data.currencies.cur_jpy
    } 
    if (localStorage.getItem("user_currency") === "CHF") {
        selectedconversionrate = data.currencies.cur_chf
    } 
    if (localStorage.getItem("user_currency") === "SGD") {
        selectedconversionrate = data.currencies.cur_sgd
    } 
    if (localStorage.getItem("user_currency") === "THB") {
        selectedconversionrate = data.currencies.cur_thb
    } 
    if (localStorage.getItem("user_currency") === "MYR") {
        selectedconversionrate = data.currencies.cur_myr
    } 
    if (localStorage.getItem("user_currency") === "BND") {
        selectedconversionrate = data.currencies.cur_bnd
    } 
    if (localStorage.getItem("user_currency") === "DKK") {
        selectedconversionrate = data.currencies.cur_dkk
    } 
    if (localStorage.getItem("user_currency") === "SEK") {
        selectedconversionrate = data.currencies.cur_sek
    }
    let markupusdprice = (data.MarkUpPrice/data.CurrencyConversionRate) * selectedconversionrate
    return markupusdprice
}
const GetPricePerCarat = (data) => {
    let selectedconversionrate = 1 
    if (localStorage.getItem("user_currency") === "INR") {
        selectedconversionrate = data.currencies.cur_inr
    } 
    if (localStorage.getItem("user_currency") === "HKD") {
        selectedconversionrate = data.currencies.cur_hkd
    } 
    if (localStorage.getItem("user_currency") === "AUD") {
        selectedconversionrate = data.currencies.cur_aud
    } 
    if (localStorage.getItem("user_currency") === "EUR") {
        selectedconversionrate = data.currencies.cur_eur
    } 
    if (localStorage.getItem("user_currency") === "CAD") {
        selectedconversionrate = data.currencies.cur_cad
    } 
    if (localStorage.getItem("user_currency") === "CNY") {
        selectedconversionrate = data.currencies.cur_cny
    } 
    if (localStorage.getItem("user_currency") === "GBP") {
        selectedconversionrate = data.currencies.cur_gbp
    } 
    if (localStorage.getItem("user_currency") === "NZD") {
        selectedconversionrate = data.currencies.cur_nzd
    } 
    if (localStorage.getItem("user_currency") === "JPY") {
        selectedconversionrate = data.currencies.cur_jpy
    } 
    if (localStorage.getItem("user_currency") === "CHF") {
        selectedconversionrate = data.currencies.cur_chf
    } 
    if (localStorage.getItem("user_currency") === "SGD") {
        selectedconversionrate = data.currencies.cur_sgd
    } 
    if (localStorage.getItem("user_currency") === "THB") {
        selectedconversionrate = data.currencies.cur_thb
    } 
    if (localStorage.getItem("user_currency") === "MYR") {
        selectedconversionrate = data.currencies.cur_myr
    } 
    if (localStorage.getItem("user_currency") === "BND") {
        selectedconversionrate = data.currencies.cur_bnd
    } 
    if (localStorage.getItem("user_currency") === "DKK") {
        selectedconversionrate = data.currencies.cur_dkk
    } 
    if (localStorage.getItem("user_currency") === "SEK") {
        selectedconversionrate = data.currencies.cur_sek
    }
    let markupusdprice = (data.MarkUpPrice/data.CurrencyConversionRate) * selectedconversionrate
    return markupusdprice/data.C_Weight
}

function handelDetails(row){
    history.push({  pathname: '/details', state:{
        StockID:row.ProductId,
        diamond_type:row.C_Color !== "fancy" ? row.WLDiamondType : row.WLDiamondType + "F"
    }})
    window.scrollTo(0, 0)
}   

// console.log(consumerData,"consumerDataconsumerData")
    return (
        <>
            {!loader?
                <>
                {selectedstones.length?<BuyPopUpNew data={selectedstones} language={language} user={user} diamondcount={selectedstones.length} totalpricepercarat={totalourprice/totalcarat} totalourprice={totalourprice} Currency={localStorage.getItem("user_currency")} setShow={setShow} show={show} GetPricePerCarat={GetPricePerCarat} GetPrice={GetPrice} symbol={Currency(localStorage.getItem("user_currency"))}/>:""}
                    <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
                    {consumerData.length > 0 ?
                        <div className="row natTab">
                            <div className="col-md-8">
                                <div className="card card-custom">
                                    <div className="card-header ">
                                        <div className="card-title align-items-start mt-3 flex-column">
                                            <div className="cursor-pointer text-hover-primary font-size-h6"  onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> Go to search results</div>
                                            <div className="d-flex align-items-center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={selectedstones.length && selectedstones.length === consumerData.filter(value => {
                                                                if(value.customer_country?.toUpperCase() === "INDIA"){
                                                                    if(value.country?.toUpperCase() === "INDIA"){
                                                                        return value
                                                                    }
                                                                }
                                                                else{
                                                                    return value
                                                                }
                                                            }).length}
                                                            className="mb-0"
                                                            inputProps={{ 'aria-labelledby': "enhanced-table-checkbox" }}
                                                            onClick={(event) => HandleMultiSelect(event)}
                                                            icon={<BsCircle />} checkedIcon={<BsCheckCircleFill />}
                                                        />
                                                    }
                                                    className="mb-0 mt-1"
                                                    label="Select All"
                                                />
                                                
                                                <div> {consumerData?consumerData.length:0} Diamonds in Cart</div>
                                            </div>
                                        </div>
                                        <div className="card-toolbar">
                                            
                                            <button  onClick={() => removeDiamond()} className="btn font-weight-bold btn-primary mt-2" >Remove from Cart</button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div
                                            className="custom-scrollbar w-100"
                                            style={{
                                                maxHeight: "63vh",
                                                position: "relative",
                                            }}
                                        >
                                        {consumerData?consumerData.map((data, index) => {
                                                const isItemSelected = isSelected(data.Certi_No);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                let available = true
                                                let checkavailable = availabilitydata.find(avvalue => avvalue?.stoneno?.toString() === data.Loat_NO?.toString())
                                                if (checkavailable?.status === "UnAvailable") {
                                                    available = false
                                                }
                                                return (
                                                    <div className={`d-flex align-items-start justify-content-start ${data.customer_country?.toUpperCase() === "INDIA" && data.country?.toUpperCase() !== "INDIA"?"outofstock-box": null}`}>
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            className="inactive-block"
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                            onClick={(event) => handleClick(event, data.Certi_No)}
                                                            icon={<BsCircle />} checkedIcon={<BsCheckCircleFill />}
                                                            disabled={data.customer_country?.toUpperCase() === "INDIA" && data.country?.toUpperCase() !== "INDIA"?true: false}
                                                        />
                                                        <div key={data.ProductId} className="d-flex w-100 mb-2 pb-1 px-4 border-bottom justify-content-between">
                                                            <div className="d-flex">
                                                                <div className="text-center p-0 inactive-block">
                                                                    {data.AWS_Image &&  data.AWS_Image !=="null" ? (
                                                                        <img
                                                                            className="img-fluid mr-2 cursor-pointer"
                                                                            alt={data.C_Shape}
                                                                            src={data.AWS_Image}
                                                                            onError={e => {
                                                                                e.currentTarget.src = toAbsoluteUrl(
                                                                                    `/media/shape/${data.C_Shape && data.C_Shape.toLowerCase()}-no.png`
                                                                                )
                                                                            }}
                                                                            onClick={() => handelDetails(data)}
                                                                            width="115px"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            className="img-fluid mr-2"
                                                                            alt={data.C_Shape}
                                                                            src={toAbsoluteUrl(
                                                                                `/media/shape/${data.C_Shape && data.C_Shape.toLowerCase()}-no.png`
                                                                            )}
                                                                            onError={e => {
                                                                                e.currentTarget.src = toAbsoluteUrl(
                                                                                    `/media/shape/noimage.jpg`
                                                                                )
                                                                            }}
                                                                            width="115px"
                                                                            onClick={() => handelDetails(data)}
                                                                            style={{ backgroundColor: "#f4f4f4" }}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="pl-2 p-0 text-left">
                                                                    <div className={`mb-1 ${data.WLDiamondType === "L" ? "labTab" : data.WLDiamondType === "N" ? "natTab" : ""} inactive-block`}>
                                                                        <span className={`${ data.WLDiamondType === "N" ? data.C_Color === "fancy" ? "F" : "W" : data.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-3 small`}>
                                                                            {data.WLDiamondType === "N" || data.WLDiamondType === "W" ? data.C_Color === "fancy"  ? "NF" : "N" : data.C_Color === "fancy" ? "LF" : "L"}
                                                                        </span>
                                                                        <span className={`text-primary font-weight-bold small ml-1`}>
                                                                            {data.WLDiamondType === "N" ? "N" : "L"}-{data.ProductId}
                                                                        </span>
                                                                        {
                                                                            hideCertificateNumber ?
                                                                            <span className="text-primary font-weight-bold mx-2 small">{data.Lab}</span> :
                                                                            
                                                                            <CertificateLink  Certificate_link={data.CERTIFICATE_LINK} lab={data.Lab} certificate={data.Certi_No}  className={`text-primary font-weight-bold mx-2 small`} />
                                                                        }                                                            
                                                                    </div>
                                                                    <span className="text-dark font-size-lg mb-2 font-weight-bold d-block inactive-block cursor-pointer" onClick={() => handelDetails(data)}>
                                                                        {data.C_Shape}{" "}
                                                                        {data.C_Weight ? parseFloat(data.C_Weight).toFixed(2): null}{" "}
                                                                        {data.C_Color === "fancy" ? (
                                                                            <>
                                                                                {data.f_color}{" "}
                                                                            </>
                                                                        ) : (
                                                                            <>{data.C_Color}{" "}</>
                                                                        )}
                                                                        {data.C_Clarity}{" "}{data.C_Cut}{" "}{data.C_Polish}{" "}{data.C_Symmetry}{" "}
                                                                        {data.C_Fluorescence}
                                                                    </span>
                                                                    <div className="font-weight-bold inactive-block">{language.CCMODE_THEME1_LIST_MEASUREMENT} : {parseFloat(data.C_Length).toFixed(2)}x{parseFloat(data.C_Width).toFixed(2)}x{parseFloat(data.C_Depth).toFixed(2)}</div>
                                                                    <div className="mb-2 inactive-block"><span className="font-weight-bold">T :</span> {data.C_TableP ? parseFloat(data.C_TableP).toFixed(2) : "00.00"}%  |  <span className="font-weight-bold">D :</span>  {data.C_DefthP ? parseFloat(data.C_DefthP).toFixed(2) : "00.00"}%</div>
                                                                    {data.customer_country?.toUpperCase() === "INDIA" && data.country?.toUpperCase() !== "INDIA" ? (
                                                                        <div className="text-danger font-weight-bolder">
                                                                            Unfortunately, Diamond location outside India cant be ordered
                                                                        </div>
                                                                    ) : data.purchased ? (
                                                                        <div className="text-info font-weight-bolder">
                                                                            Purchased
                                                                        </div>
                                                                    ) : !available ? (
                                                                        <div className="text-danger outofstock-label font-weight-bolder">
                                                                            Out Of Stock
                                                                        </div>
                                                                    ) : data.Status === "0" ? (
                                                                        <div className="text-success font-weight-bolder">
                                                                            In Stock
                                                                        </div>
                                                                    ) : (
                                                                        <div className="text-danger outofstock-label font-weight-bolder">
                                                                            Out Of Stock
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="inactive-block">
                                                                {
                                                                    !hidePrice && (                                                            
                                                                        <>
                                                                            <div className="text-right">
                                                                                <p className="text-muted font-weight-bold small mb-0 text-uppercase">{localStorage.getItem("user_currency")}/CT</p>
                                                                                <p className="text-dark font-size-lg font-weight-bold">
                                                                                    <span>{Currency(localStorage.getItem("user_currency"))}</span>
                                                                                    {numberWithCommas(GetPricePerCarat(data))}
                                                                                </p>
                                                                            </div>
                                                                            
                                                                            <div className="text-right">
                                                                                <p className="text-muted font-weight-bold small mb-0 text-uppercase">{language.CCMODE_SHORTLISTPAGE_TOTAL}</p>
                                                                                <p className="text-dark font-size-lg font-weight-bold">
                                                                                    <span>{Currency(localStorage.getItem("user_currency"))}</span>
                                                                                    {numberWithCommas(GetPrice(data))}
                                                                                </p>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }                                                                                                        
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }):<div>No Records in Cart</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card gutter-b card-custom">
                                    <div className="card-header">
                                        <div className="card-title">STONE DETAILS({selectedstones.length} Items)</div>
                                        {/* <div className="card-toolbar">
                                            
                                        </div> */}
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 d-flex mt-2 font-size-lg justify-content-between font-weight-bold">
                                                <div className="font-weight-bolder">  {language.SEARCHLIST_TOTALCARAT}: </div>
                                                <div> {numberWithCommas(totalcarat)} </div>
                                            </div>
                                            {
                                                !hidePrice && (
                                                    <>
                                                        <div className="col-12 d-flex mt-2 font-size-lg justify-content-between font-weight-bold">
                                                            <div className="font-weight-bolder">  Total {Currency(localStorage.getItem("user_currency"))}/CT:  </div>
                                                            <div> {Currency(localStorage.getItem("user_currency"))}{numberWithCommas(totalourprice/totalcarat)} </div>
                                                        </div>
                                                        <div className="col-12 border-top d-flex mt-2 font-size-h6 justify-content-between font-weight-bold">
                                                            <div className="font-weight-bolder mt-2">{language.SEARCHLIST_TOTALPAYABLE} </div>
                                                            <div className="mt-2"> {Currency(localStorage.getItem("user_currency"))}{numberWithCommas(totalourprice)} </div>
                                                        </div>
                                                    </>
                                                )
                                            }                                                                
                                            {
                                                !hidePrice && displaybuybtn ? (
                                                    <div className="col-12 text-center mt-5 ">
                                                        <button onClick={() => BuyDiamond()} className={`btn font-weight-bold btn-primary mt-2`} > Buy Diamond </button>
                                                    </div>
                                                ):""
                                            }                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        <div className="bg-white d-flex align-items-center justify-content-center container rounded" style={{height:"80vh"}}>
                                <div className="text-center">
                                    <img
                                        className="img-fluid mb-5"
                                        alt="empty-cart"
                                        width="250px"
                                        src={toAbsoluteUrl(
                                            `/media/General/empty-box.jpg`
                                        )}
                                    />
                                    <div className="font-size-h2 mb-2 font-weight-bold">Your cart is empty</div>
                                    <div className="mb-8">Looks like you haven't added any diamond <br/>to your cart yet.</div>
                                    <Link to="/searchdiamond" className="btn btn-primary btn-pill px-8">Go back to search</Link>
                                </div>
                        </div>
                    
                    }
                </>
            :
                <div className="bg-white d-flex align-items-center justify-content-center container rounded" style={{height:"80vh"}}>
                    <div className="text-center">
                        <CircularProgress color="secondary" />
                    </div>
                </div>
            }
        </>
    );
};
