import React from 'react';
import { FaAward, FaExternalLinkAlt } from 'react-icons/fa';
// import { LiaAwardSolid } from 'react-icons/lia'; // Assuming you're using react-icons for icons

const CertificateLink = ({ lab, certificate, Certificate_link, useIcon = false, className, hideLabName = false, withExternalLinkIcon= false,hideCertiNumber=false }) => {

  return (
    Certificate_link ? (
      <a
        href={Certificate_link}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {useIcon ? 
          <FaAward /> : 
          <>{
              !hideCertiNumber ?
                <>
                  {!hideLabName ? `${lab}: ` : ''} 
                    {certificate}
                </>
                : lab
              }
          </>
        }
        {withExternalLinkIcon ? <FaExternalLinkAlt className='ml-1' />:""}
      </a>
    ) : null
  );
};

export default CertificateLink;
